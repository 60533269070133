import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { ASSESSMENT_MENU } from '../../../helpers/constants';
import Helmet from 'react-helmet';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import Layout from '../../../components/Layout';

import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../../../actions/appointment';
import { getMembershipCardList } from '../../../actions/membership';
import LoaderView from '../../../components/LoaderView';
import { getPartnerId } from '../../../helpers/location';
import { getCorporateCardList, setCorporateId } from '../../../actions/partners';

const useStyles = makeStyles(theme => ({
  card: {
    width: 150,
    height: 150,
    borderRadius: '100%'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    margin: 8
  }
}));


const QuestionnaireMenuPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);

  const dispatch = useDispatch()
  const { user } = useSelector(state => state.session);

  React.useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  const fetchData = async () => {
    const partnerId = getPartnerId(window.location.search);
    dispatch(setCorporateId(partnerId));

    await dispatch(getMembershipCardList(true));
    await dispatch(getCorporateCardList(partnerId))
    await dispatch(getCategories(null, false, true));

    setLoading(false);
  }

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>แบบประเมินสุขภาพ - MorOnline</title>
      </Helmet>
      <LoaderView
        loading={loading}
      >
        <Box mt={2}>
          {
            ASSESSMENT_MENU.map(({ title, image, url }, index) => (
              <Box display='flex' flexDirection='column' alignItems='center' key={index} mb={2} onClick={() => navigate(url)}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={image}
                    />
                  </CardActionArea>
                </Card>
                <p className={classes.title}>{title}</p>
              </Box>
            ))
          }
        </Box>
      </LoaderView>

    </Layout>
  );
}

export default QuestionnaireMenuPage;
